/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { TokenField } from '@loggi/components/src/one/formik-fields';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import palette from '@loggi/mar/src/palette';
import { useSnackbar } from 'notistack';
import Toast from '@loggi/components/src/one/toast';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Company from '@loggi/components/src/one/hooks/Company';
import ComboSingleSelect from '../forms/combo-single-select.component';
import { UrlField } from '../forms/url-field.component';
import { codeIntegratorStyles } from '../../styles';
import integratorListApi from '../../services/integrator-api';
import { SectionTitle, SubSectionTitle } from '../titles.component';

const TrayCodeIntegrator = ({ integrator }) => {
  const { companyId } = useParams();
  const { t } = useTranslation('management');
  const [integratorId, setIntegratorId] = useState();
  const [configExists, setConfigExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const integratorName = t(`${integrator}.name`);
  const schedulingUrl = `/empresas/${companyId}/coletas/adicionar`;
  const companyData = Company.getInstance().getCompanyData();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = codeIntegratorStyles();
  const [savedFormValues, setSavedFormValues] = useState({});
  const [code, setCode] = useState();
  const hintText = t('tray.hint.text', { returnObjects: true });
  const helpText = t('tray.help.text', { returnObjects: true });
  const defaultPickupType = 'PICKUP_TYPE_SPOT';
  const [checkedPickupType, setCheckedPickupType] = useState(defaultPickupType);

  function errorToast(key, message) {
    return <Toast id={key} message={message} type="error" />;
  }

  function successToast(key, message) {
    return <Toast id={key} message={message} type="success" />;
  }

  const handleSnackbarError = useCallback(
    error => {
      enqueueSnackbar(error.message, errorToast);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    setLoading(true);
    integratorListApi
      .integratorsList(companyId)
      .then(resp => {
        const integratorCompany = resp
          .filter(integration => integration.integrator.name === integratorName)
          .map(integrationResp => {
            setIntegratorId(integrationResp.integrator.accessKey);
            setCode(integrationResp.id);
            return integrationResp;
          });
        if (integratorCompany.length === 0) {
          integratorListApi
            .integratorCode(companyId, { integrator_name: integratorName })
            .then(resp)
            .catch(error => handleSnackbarError(error));
        }
        setLoading(false);
      })
      .catch(error => handleSnackbarError(error));
    setLoading(false);
  }, [companyId, integratorName, handleSnackbarError]);

  useEffect(() => {
    if (code) {
      setLoading(true);
      integratorListApi
        .getScheduleConfig('TRAY_SHIPMENT', code)
        .then(resp => {
          setSavedFormValues({
            trayCode: resp.authentication.apiKey,
            trayUrl: resp.endpoint,
            pickupType: resp.metadata.pickupType
          });
          setCheckedPickupType(resp.metadata.pickupType || defaultPickupType);
          setConfigExists(true);
        })
        .catch(() => {
          setSavedFormValues({});
        });
      setLoading(false);
    }
  }, [code]);

  const handleError = error => {
    enqueueSnackbar(error.message, errorToast);
  };

  const saveValues = formValues => {
    const handleResponse = () => {
      setSavedFormValues(formValues);
      enqueueSnackbar(
        t('integrations.successMessages.goToPickupConfig'),
        successToast
      );
      push(schedulingUrl);
    };
    const CreatePayload = values => ({
      companyId,
      integrator: integratorId,
      externalId: code,
      endpoint: values.trayUrl,
      maxPackagesPerFetch: '50',
      timeZoneId: 'GMT-3',
      metadata: {
        federalTaxId: companyData.cnpj,
        integrationCode: code,
        pickupType: checkedPickupType
      },
      authentication: {
        endpoint: values.trayUrl,
        apiKey: values.trayCode
      },
      active: false
    });

    const handleFinally = () => {
      setLoading(false);
    };

    function createConfig() {
      integratorListApi
        .createScheduleConfig('TRAY_SHIPMENT', CreatePayload(formValues))
        .then(handleResponse)
        .catch(handleError)
        .finally(handleFinally);
    }
    function updateConfig() {
      integratorListApi
        .updateScheduleConfig('TRAY_SHIPMENT', CreatePayload(formValues), code)
        .then(handleResponse)
        .catch(handleError)
        .finally(handleFinally);
    }

    setLoading(true);
    if (configExists) {
      updateConfig();
    } else {
      createConfig();
    }
  };

  return (
    <Formik
      initialValues={savedFormValues}
      onSubmit={saveValues}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form data-testid="tray-form">
            <Box>
              <Box mb={3}>
                <SectionTitle>{t('tray.config.title')}</SectionTitle>
                <Typography variant="subtitle2">
                  {t('tray.config.text')}
                </Typography>
              </Box>

              <Divider />
              <Box mt={3} mb={3}>
                <SubSectionTitle>{t('tray.code.title')}</SubSectionTitle>
                <Typography variant="subtitle2">
                  {t('tray.code.text')}
                  <Box mt={3} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TokenField
                          fieldName="trayCode"
                          minValue={10}
                          maxValue={100}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
                <SubSectionTitle>{t('tray.url.title')}</SubSectionTitle>
                <Typography variant="subtitle2">
                  {t('tray.url.text')}
                  <Box mt={3} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <UrlField fieldName="trayUrl" />
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>

                <SubSectionTitle>{t('tray.pickup.title')}</SubSectionTitle>
                <Typography variant="subtitle2">
                  {t('tray.pickup.description')}
                  <Box mt={3} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ComboSingleSelect
                          title={t('tray.pickup.subTitle')}
                          setCheckedCallback={setCheckedPickupType}
                          comboBoxOptions={[
                            {
                              labelText: 'Coleta',
                              value: 'PICKUP_TYPE_SPOT'
                            },
                            {
                              labelText: 'Coleta Recorrente',
                              value: 'PICKUP_TYPE_MILK_RUN'
                            },
                            {
                              labelText: 'Drop Off',
                              value: 'PICKUP_TYPE_DROP_OFF'
                            }
                          ]}
                          checkedValue={checkedPickupType}
                          filedName="pickupType"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>

                <Box
                  mt={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <Button
                      color="primary"
                      disabled={!isValid || loading}
                      data-testid="trayForm-saveButton"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {!loading && t('tray.saveButtonText')}
                      {loading && (
                        <CircularProgress
                          style={{
                            color: palette.common.white,
                            left: '50%',
                            marginLeft: -12,
                            marginTop: -12,
                            position: 'absolute',
                            top: '50%'
                          }}
                          data-testid="loading"
                          size={24}
                        />
                      )}
                    </Button>
                  </Grid>
                </Box>
              </Box>

              <Divider />

              <Box mt={3} mb={3}>
                <Box mb={3}>
                  <SubSectionTitle>{t('tray.hint.title')}</SubSectionTitle>
                  {hintText[0]}
                  <Link
                    href={t('tray.links.site')}
                    className={styles.integratorLink}
                  >
                    {hintText[1]}
                  </Link>
                  {hintText[2]}
                </Box>
                <Box mb={3}>
                  <SubSectionTitle>{t('tray.help.title')}</SubSectionTitle>
                  {helpText[0]}
                  <Link
                    href={t('tray.links.help')}
                    className={styles.integratorLink}
                  >
                    {helpText[1]}
                  </Link>
                  {helpText[2]}
                  <Link
                    href={t('tray.links.forms')}
                    className={styles.integratorLink}
                  >
                    {helpText[3]}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

TrayCodeIntegrator.propTypes = {
  integrator: PropTypes.string
};

TrayCodeIntegrator.defaultProps = {
  integrator: 'tray'
};

export default TrayCodeIntegrator;
